import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom'; // Import Link for routing
import { FaBars, FaTimes } from 'react-icons/fa'; // Import FaTimes for close icon
import { useAuth0 } from '@auth0/auth0-react'; // Import Auth0 hook
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0(); // Get Auth0 functions
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallBanner, setShowInstallBanner] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0); // Track the banner height

  // Handle PWA Install Prompt
  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault(); // Prevent the automatic prompt
      setDeferredPrompt(event);
      setShowInstallBanner(true); // Show the banner
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null); // Reset the deferred prompt
        setShowInstallBanner(false); // Hide the banner
      });
    }
  };

  // Hide the install banner if the app is already installed
  useEffect(() => {
    window.addEventListener('appinstalled', () => {
      setShowInstallBanner(false);
    });
  }, []);

  // Update the banner height dynamically
  useEffect(() => {
    const banner = document.getElementById('install-banner');
    if (banner) {
      setBannerHeight(banner.offsetHeight);
    }
  }, [showInstallBanner]);

  const getPageName = () => {
    const { pathname } = location;
  
    switch (true) {
      case pathname.startsWith('/help-support/alerts'):
        return 'Alerts';
      case pathname === '/market-insights':
        return 'Market Insights';
      case pathname === '/help-support/faqs':
        return 'Frequently Asked Questions';
      case pathname === '/help-support':
        return 'Help & Support';
      case pathname === '/documents-reports':
          return 'Documents & Reports';
      case pathname === '/':
        return 'Home';
      default:
        return 'Market Insights'; // Default for other undefined routes
    }
  };

  // Toggle the menu state
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="w-full flex flex-col items-center" style={{ paddingTop: `${bannerHeight}px` }}>
      {/* Install Banner */}
      {showInstallBanner && (
        <div id="install-banner" style={bannerStyles}>
          <span>Install the Market Insights app for quick access to the latest insights!</span>
          <button onClick={handleInstallClick} style={buttonStyles}>
            Install
          </button>
        </div>
      )}

      {/* Header (title and navigation bar) */}
      <Header />

      <nav className="hidden md:flex w-full border-b border-gray-300 py-4">
        <div className="w-4/5 mx-auto flex items-center">
          <Link to="/" className="text-lg font-bold text-gray-700">
            Home
          </Link>
          <Link to="/market-insights" className="text-lg font-bold text-gray-700 ml-8">
            Market Insights
          </Link>
          <Link to="/market-insights-carousel" className="text-lg font-bold text-gray-700 ml-8">
            Market Insights v2
          </Link>
          <Link to="/documents-reports" className="text-lg font-bold text-gray-700 ml-8">
            Documents & Reports
          </Link>
          <Link to="/help-support" className="text-lg font-bold text-gray-700 ml-8">
            Help & Support
          </Link>
        </div>
      </nav>

      {/* Darker blue banner with the page name and darker green bar */}
      <div className="w-full bg-blue-900 py-4 md:py-16">
        <div className="w-4/5 mx-auto flex items-center">
          {/* Hamburger Icon on small screens */}
          <button
            onClick={toggleMenu}
            className="text-3xl text-white mr-4 focus:outline-none md:hidden"
          >
            <FaBars />
          </button>
          {/* Darker green vertical bar */}
          <span className="text-xl md:text-4xl text-green-500 font-bold mr-4">|</span>
          {/* Page name - left-aligned */}
          <h2 className="text-xl md:text-4xl font-bold text-white flex-grow text-left">
            {getPageName()}
          </h2>
        </div>
      </div>

      {/* Flyout Menu for small screens */}
      <div
        className={`fixed left-0 h-full w-64 bg-white shadow-lg transform ${
          isMenuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-50 md:hidden`}
        style={{ top: `${bannerHeight}px` }} // Push down based on banner height
      >
        <div className="flex justify-between items-center p-5 border-b border-gray-300">
          <h2 className="text-xl font-bold">Market Insights</h2>
          <button onClick={toggleMenu} className="text-3xl">
            <FaTimes />
          </button>
        </div>
        <nav className="flex flex-col p-5 space-y-4">
          <Link to="/" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Home
          </Link>
          <Link to="/market-insights" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Market Insights
          </Link>
          <Link to="/market-insights-carousel" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Market Insights v2
          </Link>
          <Link to="/documents-reports" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Documents & Reports
          </Link>
          <Link to="/help-support" className="text-lg font-bold text-gray-700" onClick={toggleMenu}>
            Help & Support
          </Link>

          {/* Conditionally render login or logout button only in the mobile menu */}
          {isAuthenticated ? (
            <button
              className="bg-blue-700 text-white px-4 py-2 rounded-md font-bold md:hidden" // Hidden on medium/large screens
              onClick={() => {
                toggleMenu();
                logout({ returnTo: window.location.origin });
              }}
            >
              Logout
            </button>
          ) : (
            <button
              className="bg-blue-700 text-white px-4 py-2 rounded-md font-bold md:hidden" // Hidden on medium/large screens
              onClick={() => {
                toggleMenu();
                loginWithRedirect();
              }}
            >
              Login
            </button>
          )}
        </nav>
      </div>

      {/* Main Content */}
      <main className="w-4/5 mx-auto py-8">{children}</main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

// Styles for the banner
const bannerStyles = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  backgroundColor: '#007bff',
  color: 'white',
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000,
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)', // Stronger shadow
};

// Styles for the install button
const buttonStyles = {
  backgroundColor: 'white',
  color: '#007bff',
  padding: '10px 20px',
  borderRadius: '5px',
  border: 'none',
  cursor: 'pointer',
};

export default Layout;
