import React, { useState, useEffect } from 'react';
import { Table, Button, DatePicker, Select } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';  // Import dayjs
import isBetween from 'dayjs/plugin/isBetween';  // Import plugin for range comparison

dayjs.extend(isBetween);  // Extend dayjs with isBetween plugin

const { RangePicker } = DatePicker;

const DocumentsReportsPage = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({ account: '', category: '', documentType: '' });
  const [dateRange, setDateRange] = useState([]);

  // Fetch documents from API/DynamoDB
  useEffect(() => {
    const fetchDocuments = async () => {
      const response = await axios.get('https://i7kd5bnqf2.execute-api.ap-southeast-2.amazonaws.com/dev/documents'); // Replace with actual API endpoint
      const data = JSON.parse(response.data.body);
      const documents = data.map((doc, index) => ({
        ...doc,
        key: index,
        IssueDateFormatted: dayjs(doc.IssueDate, 'YYYYMMDD').format('DD MMMM YYYY'), // Format the date with dayjs
      }));
      setData(documents);
      setFilteredData(documents);
    };
    fetchDocuments();
  }, []);

  // Get unique values for filters
  const getUniqueValues = (field) => {
    return [...new Set(data.map((item) => item[field]))].map((value) => ({
      text: value,
      value,
    }));
  };

  // Table columns with filters and sorting
  const columns = [
    {
      title: 'Account',
      dataIndex: 'Account',
      sorter: (a, b) => a.Account.localeCompare(b.Account),
      filters: getUniqueValues('Account'),
      onFilter: (value, record) => record.Account === value,
      filterMultiple: true,
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      sorter: (a, b) => a.Category.localeCompare(b.Category),
      filters: getUniqueValues('Category'),
      onFilter: (value, record) => record.Category === value,
      filterMultiple: true,
    },
    {
      title: 'Document Type',
      dataIndex: 'DocumentType',
      sorter: (a, b) => a.DocumentType.localeCompare(b.DocumentType),
      filters: getUniqueValues('DocumentType'),
      onFilter: (value, record) => record.DocumentType === value,
      filterMultiple: true,
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: 'Issue Date',
      dataIndex: 'IssueDateFormatted',
      sorter: (a, b) => dayjs(a.IssueDate, 'YYYYMMDD').unix() - dayjs(b.IssueDate, 'YYYYMMDD').unix(),
      defaultSortOrder: null,
    },
    {
      title: 'Download',
      render: (text, record) => (
        <Button
          type="link"
          icon={<DownloadOutlined />}
          onClick={() => handleDownload(record.FileUrl)} // Use the file name stored in the database
          target="_blank"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      ),
    },
  ];

  const handleDateChange = (dates) => {
    console.log("setting date range: " + JSON.stringify(dates));
    if (dates && dates.length === 2) {
      // Format the date range to YYYYMMDD format with dayjs
      const formattedDates = [
        dates[0].format('YYYYMMDD'),
        dates[1].format('YYYYMMDD')
      ];
      console.log("Formatted date range: ", formattedDates);

      setDateRange(formattedDates);
    } else {
      setDateRange([]); // Reset the date range if no valid date is selected
    }
  };

  // Apply filters
  useEffect(() => {
    const filtered = data.filter((item) => {
      const accountMatch = filters.account ? item.Account === filters.account : true;
      const categoryMatch = filters.category ? item.Category === filters.category : true;
      const docTypeMatch = filters.documentType ? item.DocumentType === filters.documentType : true;
      const dateMatch = dateRange.length === 0 || (
        dayjs(item.IssueDate, 'YYYYMMDD').isBetween(dateRange[0], dateRange[1], null, '[]')
      );
      return accountMatch && categoryMatch && docTypeMatch && dateMatch;
    });
    setFilteredData(filtered);
  }, [filters, dateRange, data]);

  // Date picker shortcuts
  const handleDateRangeShortcut = (period) => {
    const now = dayjs();
    const currentFinYearStart = dayjs().month(6).startOf('month'); // 1 July
    const lastFinYearStart = currentFinYearStart.subtract(1, 'year');

    if (period === 'current') {
      setDateRange([currentFinYearStart.format('YYYYMMDD'), now.format('YYYYMMDD')]);
    } else if (period === 'last') {
      setDateRange([lastFinYearStart.format('YYYYMMDD'), currentFinYearStart.subtract(1, 'day').format('YYYYMMDD')]);
    } else {
      setDateRange([]);
    }
  };

  const handleDownload = async (fileName) => {
    try {
      // Request the pre-signed URL from the backend
      fileName = 'Sample Document.pdf';
      console.info('Getting Pre-signed URL for:', fileName);
      const response = await axios.post('https://i7kd5bnqf2.execute-api.ap-southeast-2.amazonaws.com/dev/documents/get-presigned-url', { fileName });
      console.info('Pre-signed URL response:', JSON.stringify(response));
      console.info('Pre-signed URL body url:',  JSON.parse(response.data.body).url);

      // Download the file using the pre-signed URL
      const url = JSON.parse(response.data.body).url;
      window.open(url, '_blank'); // Open the file in a new tab or initiate download
    } catch (error) {
      console.error('Error fetching pre-signed URL:', error);
    }
  };

  return (
    <div className="w-4/5 mx-auto py-8">
      <h2 className="text-2xl font-bold mb-6">Documents & Reports</h2>

      {/* Date range picker and shortcuts */}
      <div className="mb-4 flex flex-wrap gap-4">
        <RangePicker onChange={handleDateChange} />
        <Button onClick={() => handleDateRangeShortcut('all')}>All</Button>
        <Button onClick={() => handleDateRangeShortcut('current')}>Current Financial Year</Button>
        <Button onClick={() => handleDateRangeShortcut('last')}>Previous Financial Year</Button>
      </div>

      {/* Table */}
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={filteredData.length > 10 ? { pageSize: 10 } : false}
      />
    </div>
  );
};

export default DocumentsReportsPage;
