import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import Splash from './pages/Splash';
import Home from './pages/Home';
import MarketInsights from './pages/MarketInsights';
import MarketInsightsCarousel from './pages/MarketInsightsCarousel';
import MarketInsightsArticle from './pages/MarketInsightsArticle';
import FAQs from './pages/FAQs'; // Import the new FAQs page
import PrivateRoute from './components/PrivateRoute';
import NotificationComponent from './components/NotificationComponent'; // Import NotificationComponent
import HelpSupport from './pages/HelpSupport'; // New Help & Support Page
import Alerts from './pages/Alerts'; // New Alerts Page
import AlertsArticle from './pages/AlertsArticle'; // New Alerts Page
import DocumentsReportsPage from './pages/DocumentsReports'; // Import the new page


function App() {
  const { isAuthenticated } = useAuth0();
  
  useEffect(() => {
    // Set page title and favicon
    document.title = 'Market Insights';
    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = '/favicon.ico'; // Ensure the favicon is placed in the public folder
    }
  }, []);

  return (
    <Router>
      <Layout>
        {/* Notification logic is handled here */}
        <NotificationComponent />

        <Routes>
          {/* Splash page route */}
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/home" /> : <Splash />}
          />
          {/* Protected routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/market-insights" element={<MarketInsights />} />
            <Route path="/market-insights-carousel" element={<MarketInsightsCarousel />} />
            <Route path="/market-insights/:slug" element={<MarketInsightsArticle />} />
            <Route path="/documents-reports" element={<DocumentsReportsPage />} />  {/* Add the new route */}
            {/* Help & Support Route */}
            <Route path="/help-support" element={<HelpSupport />} />
            <Route path="/help-support/faqs" element={<FAQs />} />
            <Route path="/help-support/alerts" element={<Alerts />} />
            <Route path="/help-support/alerts/:slug" element={<AlertsArticle />} />
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
