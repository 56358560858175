import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
import sanityClient from '../sanityClient';
import { urlFor } from '../imageBuilder';

const Alerts = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [categoryTypes, setCategoryTypes] = useState([]);

  useEffect(() => {
    // Fetch the latest articles from Sanity
    sanityClient
      .fetch(
        `*[_type == "alert"] | order(publishedAt desc)[0...10]{
          _createdAt,
          name,
          author->{name},
          slug,
          banner,
          synopsis,
          categoryType,
          publishedAt
        }`
      )
      .then((data) => {
        setArticles(data);
        setFilteredArticles(data);
        // Extract unique categoryTypes
        const uniqueCategories = ['All', ...new Set(data.map(article => article.categoryType))];
        setCategoryTypes(uniqueCategories);
      })
      .catch(console.error);
  }, []);

  // Filter articles by selected category
  const filterByCategory = (category) => {
    setSelectedCategory(category);
    if (category === 'All') {
      setFilteredArticles(articles);
    } else {
      setFilteredArticles(articles.filter(article => article.categoryType === category));
    }
  };

  return (
    <div className="w-full md:w-4/5 mx-auto py-8">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
        {/* Left Column (Articles) */}
        <div className="lg:col-span-3">
          {/* Content Title */}
          <h2 className="text-2xl font-bold mb-6">Latest Alerts</h2>

          {/* Responsive Filter: Pills for large screens, Dropdown for small screens */}
          <div className="flex items-center mb-6">
            <h3 className="text-sm font-normal mr-4">Filter by:</h3>

            {/* Pills for medium and larger screens */}
            <div className="hidden sm:flex space-x-2">
              {categoryTypes.map((category, idx) => (
                <div
                  key={idx}
                  className={`px-2 py-1 rounded-full cursor-pointer border text-sm ${
                    selectedCategory === category ? 'bg-blue-700 text-white' : 'bg-white text-gray-800 border-gray-300'
                  }`}
                  onClick={() => filterByCategory(category)}
                >
                  {category}
                </div>
              ))}
            </div>

            {/* Dropdown for small screens */}
            <div className="block sm:hidden w-full">
              <select
                className="block w-full bg-white border border-gray-300 text-gray-800 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                value={selectedCategory}
                onChange={(e) => filterByCategory(e.target.value)}
              >
                {categoryTypes.map((category, idx) => (
                  <option key={idx} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Articles List */}
          <div className="grid grid-cols-1 gap-4">
            {filteredArticles.map((article, idx) => (
              <React.Fragment key={article.slug.current}>
                <div className="relative">
                  <div className="relative">
                    {/* Inset the date pill from the image */}
                    <div className="absolute top-2 left-2 bg-white text-sm font-bold px-3 py-1 rounded-md shadow z-10">
                      {new Date(article.publishedAt || article._createdAt).toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </div>
                    <img
                      src={urlFor(article.banner).width(800).url()}
                      alt={article.name}
                      className="w-full h-48 object-cover rounded-lg"
                    />
                  </div>
                  <div className="px-4 pt-4 pb-0">
                    {/* Category Pill and Author Name */}
                    <div className="flex items-center mb-1">
                      <div className="bg-orange-100 text-orange-900 text-xs font-bold px-2 py-1 rounded-full mr-2">
                        {article.categoryType}
                      </div>
                      <p className="text-sm text-gray-700">
                        Author: {article.author.name}
                      </p>
                    </div>
                    <Link to={`/help-support/alerts/${article.slug.current}`}>
                      <h3 className="text-lg font-semibold hover:underline mt-2 mb-1">
                        {article.name}
                      </h3>
                    </Link>
                    <p className="text-sm mt-2">{article.synopsis}</p>
                  </div>
                </div>

                {idx < articles.length - 1 && (
                  <hr className="my-4 border-gray-300" />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Right Column (Reports) */}
        <div className="lg:col-span-1">
          <div className="flex items-center mb-4">
            <FaInfoCircle className="text-green-500 text-lg mr-2" /> 
            <h3 className="text-lg font-bold">Guidelines</h3>
          </div>

          <div className="bg-gray-100 p-2 shadow-md">
            <ul className="space-y-4">
              <li className="bg-white p-3 shadow">
                <span className="text-md font-bold">Pest Control</span>
              </li>
              <li className="bg-white p-3 shadow">
                <span className="text-md font-bold">Disease Control</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alerts;
