// src/pages/Home.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaChartLine, FaLifeRing, FaFileAlt } from 'react-icons/fa';
import sanityClient from '../sanityClient';
import { urlFor } from '../imageBuilder';

const Home = () => {
  const [advert, setAdvert] = useState(null);

  useEffect(() => {
    const fetchAdvert = async () => {
      const adverts = await sanityClient.fetch(`
        *[_type == "advert"]{
          startDate,
          endDate,
          advertImage,
          targetUrl,
          orientation
        }
      `);

      const activeAdverts = adverts.filter(ad => {
        const now = new Date();
        const startDate = new Date(ad.startDate);
        const endDate = new Date(ad.endDate);
        return now >= startDate && now <= endDate;
      });

      if (activeAdverts.length > 0) {
        const randomAdvert = activeAdverts[Math.floor(Math.random() * activeAdverts.length)];
        setAdvert(randomAdvert);
      }
    };

    fetchAdvert();
  }, []);

  return (
    <div className="w-full">
      {/* Horizontal Advert */}
      {advert && advert.orientation === 'Horizontal' && (
        <div className="w-full">
          <a
            href={advert.targetUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={urlFor(advert.advertImage).url()}
              alt="Advertisement"
              className="w-full h-auto object-cover mb-4"
              style={{ maxHeight: '200px' }}
            />
          </a>
        </div>
      )}

       

        {/* Main Content Area */}
        <div className="w-full flex justify-center items-start min-h-screen bg-gray-100 py-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full max-w-2xl">  

          {/* Market Insights Tile */}
          <Link
            to="/market-insights"
            className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg transition p-6"
            style={{ aspectRatio: '1/1' }}
          >
            <FaChartLine className="text-green-600 text-3xl mb-3" />
            <h2 className="text-xl font-bold mb-2">Market Insights</h2>
            <p className="text-gray-600 text-sm">Get the latest insights on market trends, data, and analysis.</p>
          </Link>

          {/* Documents & Reports Tile */}
          <Link
            to="/documents-reports"
            className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg transition p-6"
            style={{ aspectRatio: '1/1' }}
          >
            <FaFileAlt className="text-green-600 text-3xl mb-3" />
            <h2 className="text-xl font-bold mb-2">Documents & Reports</h2>
            <p className="text-gray-600 text-sm">View and download your documents and reports.</p>
          </Link>
          
          {/* Help & Support Tile */}
          <Link
            to="/help-support"
            className="flex flex-col justify-start items-start bg-white rounded-lg shadow-md hover:shadow-lg transition p-6"
            style={{ aspectRatio: '1/1' }}
          >
            <FaLifeRing className="text-green-600 text-3xl mb-3" />
            <h2 className="text-xl font-bold mb-2">Help & Support</h2>
            <p className="text-gray-600 text-sm">Access FAQs and Alerts.</p>
          </Link>
        </div>
        </div>

        {/* Vertical Advert for Smaller Screens */}
        {advert && advert.orientation === 'Vertical' && (
          <div className="flex sm:hidden justify-center mt-4">
            <a
              href={advert.targetUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={urlFor(advert.advertImage).url()}
                alt="Advertisement"
                className="w-3/4 h-auto object-cover max-h-[300px]"
              />
            </a>
          </div>
        )}
      </div>
  
  );
};

export default Home;
