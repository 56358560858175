// src/auth0-config.js
export const auth0Config = {
    domain: 'dev-fmzq35k07rz8kzxw.us.auth0.com',
    clientId: 'oRmns14hJEF6FOSJoQHO7n3uUYB09dLQ',
    redirectUri: window.location.origin, // This is where Auth0 will redirect the user after login
    logoutRedirectUri: window.location.origin, 
    audience: 'YOUR_API_IDENTIFIER', // Optional for API authentication
    scope: 'openid profile email offline_access'
  };


  