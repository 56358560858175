import React, { useEffect } from 'react';

// Backend API URL (replace with your actual backend URL)
const BACKEND_API_URL = 'https://qje70umftf.execute-api.ap-southeast-2.amazonaws.com/dev/subscribe'; // Replace this with your backend API

const NotificationComponent = () => {
  useEffect(() => {
    // Check if Service Worker and PushManager are supported
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      // Request permission from the user
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          // Wait until the service worker is ready
          navigator.serviceWorker.ready.then(swRegistration => {
            // Check if the user is already subscribed
            swRegistration.pushManager.getSubscription().then(subscription => {
              if (!subscription) {
                // If the user is not subscribed, subscribe them to push notifications
                subscribeUser(swRegistration);
              } else {
                console.log('User is already subscribed:', subscription);
              }
            });
          });
        } else {
          console.log('Push notification permission was denied.');
        }
      });
    }
  }, []);

  const subscribeUser = async (swRegistration) => {
    try {
      // Subscribe the user to push notifications
      const subscription = await swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          'BEsIwB8R28me5o62A6Dq4O_1VE-ZiDlW9IFvtpSe_QJ8-5ejKYjp70Wnh1Ze-2m7tvVQsQ8MezdKQn1K9Qhpy1Q' // Replace with your VAPID public key
        ),
      });

      // Send subscription to the backend
      alert("sending subscription: "+JSON.stringify(subscription))
      await sendSubscriptionToBackend(subscription);
    } catch (err) {
      console.error('Failed to subscribe the user: ', err);
      alert("sending subscription failed: "+err)
    }
  };

  const sendSubscriptionToBackend = async (subscription) => {
    try {
      const response = await fetch(BACKEND_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(subscription),
      });

      if (!response.ok) {
        throw new Error('Failed to send subscription to backend');
        alert("bad response: "+JSON.stringify(response))

      }
      console.log('Subscription sent to backend successfully:', subscription);
    } catch (err) {
      alert("sending subscription failed2: "+err)

      console.error('Failed to send subscription to backend:', err);
    }
  };

  // Helper function to convert VAPID key
  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  return null; // This component doesn't render anything
};

export default NotificationComponent;
