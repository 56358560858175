import React, { useEffect, useState } from 'react';

const Footer = () => {
  return (
    <footer className="w-full bg-gray-100 border-t border-gray-300 py-4">
      <div className="w-4/5 mx-auto text-center">
        <p className="text-sm text-gray-500">&copy; 2024 Market Insights. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
